<template>
    <div class="container block sm:px-2 md:p-0 px-2">
        <div class="block border border-solid border-t-0 border-r-0 border-l-0 py-2 mb-0" v-for="(item, n) in data" :key="n">
            <div class="flex justify-between" v-if="windowWidth > 600">
                <div class="block w-4/5">
                    <span class="font-semibold text-md mb-0">{{ item.Name }}</span>
                    <div v-html="item.Description" class="text-gray-500"></div>
                </div>
                <div class="block">
                    <span class="text-gray-500 block mb-0 text-right">From</span>
                    <span class="font-semibold block text-right">{{ item.CurrencyCode }} {{ formatNumber(item.Amount) }}</span>
                    <div class="flex w-1/4 mb-2">
                        <b-button type="is-success mr-3" size="is-small" @click="qty--">-</b-button>
                        <input class="text-center border-none" style="width: 50px;" step="1" type="number" :value="qty > item.ActionInputQty.maxOrder ? qty = item.ActionInputQty.maxOrder : qty < item.ActionInputQty.minOrder ? qty = item.ActionInputQty.minOrder : qty" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5" />
                        <b-button type="is-success" size="is-small" @click="qty++">+</b-button>
                    </div>
                    <b-button type="is-dark" class="w-full" @click="book(item)">Book Now</b-button>
                </div>
            </div>
            <div class="flex py-3 cursor-pointer" v-else>
                <div v-if="company.User.Name !== 'elitefutsal1'" class="flex justify-start" style="min-width: 70px;">
                    <div class="rounded-full border border-solid self-center" style="width: 50px; height: 50px;"></div>
                </div>
                <div class="block" :class="company.User.Name === 'elitefutsal1' ? 'w-full' : ''">
                    <div class="flex justify-between w-full">
                        <span class="font-semibold">{{ item.Name }}</span>
                        <span class="font-semibold block text-right">{{ item.CurrencyCode }} {{ formatNumber(item.Amount) }}</span>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <small v-if="item.Description.search('div') === 1" class="text-gray-500" v-html="item.Description"></small>
                            <small v-else class="text-gray-500">{{ item.Description }}</small>
                        </div>
                        <div class="mt-1">
                            <div class="flex w-1/4 mb-2">
                                <b-button type="is-success mr-3" size="is-small" @click="qty--">-</b-button>
                                <input class="text-center border-none" style="width: 50px;" step="1" type="number" :value="qty > item.ActionInputQty.maxOrder ? qty = item.ActionInputQty.maxOrder : qty < item.ActionInputQty.minOrder ? qty = item.ActionInputQty.minOrder : qty" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5" />
                                <b-button type="is-success" size="is-small" @click="qty++">+</b-button>
                            </div>
                            <b-button type="is-dark" class="w-full" @click="book(item)">Book Now</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            company: JSON.parse(localStorage.getItem('Company'))
        }
    },
    data: () => ({
        qty: window.innerWidth < 767 ? 1 : 0
    }),
    methods: {
        book (item) {
            this.$router.push('/' + item.Action.get + '&item=' + this.$route.query.Oid + '&Qty=' + this.qty)
        }
    },
    mounted () {
        localStorage.setItem('Oid', JSON.stringify(this.$route.query.Oid))
        localStorage.removeItem('backHome')
        localStorage.removeItem('backHome2')
        localStorage.removeItem('backHome3')
        console.log('detail product data', this.data)
    }
}
</script>
